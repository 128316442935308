export const GALLERY_ATTRS = [
  "Background",
  "Body",
  "Eyes",
  "Tai Chi Star",
  "Head",
  "Style",
  "Ring",
  "Special",
];
